import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {isTryingDirectLogin, UserService} from '@drklein-pk/customer-core-lib';
import {JsonConvert} from 'json2typescript';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, distinctUntilChanged, filter, flatMap, map, shareReplay} from 'rxjs/operators';
import {CustomerApp, CustomerAppConfig} from './customer-app';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';

interface AppPermission {
  id: string;
  _embedded: {
    app: {
      id: string;
      sortOrder: number;
      name: string;
      displayName: string;
      appUrl: string;
      description: string;
    };
  };
}

/**
 * Static app permissions object
 * Endkundenportal Team decided to use static permissions until fetching via a service is needed
 */
const staticAppPermissions: { _embedded: { appPermissions: AppPermission[] } } = {
  _embedded: {
    appPermissions: [
      {
        id: '4a22ad5c-c672-4fb9-b370-8210bbb31482',
        _embedded: {
          app: {
            id: 'cfd3107b-b012-4418-b864-1260afdae2ac',
            sortOrder: 2,
            name: 'customer-data-app',
            displayName: _('apps.data.name'),
            appUrl: 'customer-data-app',
            description: '',
          },
        },
      },
      {
        id: '4a22ad5c-c672-4fb9-b370-8210bbb5547',
        _embedded: {
          app: {
            id: '36693700-82f0-4e5f-a10b-edfa03be6eb7',
            sortOrder: 3,
            name: 'customer-consultants-app',
            displayName: _('apps.consultant.name.male'),
            appUrl: 'customer-consultants-app',
            description: '',
          },
        },
      },
      {
        id: '4a22ad5c-c672-4fb9-b370-8210bbb5546',
        _embedded: {
          app: {
            id: 'bbb325f0-731d-47b3-bcdd-cdad00f8e4d8',
            sortOrder: 4,
            name: 'customer-documents-app',
            displayName: _('apps.documents.name'),
            appUrl: 'customer-documents-app',
            description: '',
          },
        },
      },
      {
        id: '4a22ad5c-c672-4fb9-b370-8210bbc8547',
        _embedded: {
          app: {
            id: '36ad5h00-82f0-4e5f-a10b-edfa03be6eb7',
            sortOrder: 1,
            name: 'customer-projects-app',
            displayName: _('apps.projects.name'),
            appUrl: 'customer-projects-app',
            description: '',
          },
        },
      }
    ],
  },
};

interface AppRoute {
  appId: string;
  path: string;
}

@Injectable()
export class CustomerAppService {
  private readonly appData$: Observable<CustomerApp[]>;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.appData$ = (isTryingDirectLogin()
      ? this.loadAppPermissions()
      : this.userService.getUserId(true).pipe(
        distinctUntilChanged((
          a,
          b,
        ) => a !== b),
        flatMap(() => this.loadAppPermissions()),
      )).pipe(
      shareReplay(1),
      catchError(() => of([]),
      ),
    );
  }

  public getApps(): Observable<CustomerApp[]> {
    return this.appData$;
  }

  public getRoutes(): Observable<AppRoute[]> {
    // TODO: rename routes
    // TODO: Get from appPermissionService
    return of([
      {appId: 'cfd3107b-b012-4418-b864-1260afdae2ac', path: 'customer-data'},
      {appId: '36693700-82f0-4e5f-a10b-edfa03be6eb7', path: 'consultants'},
      {appId: 'bbb325f0-731d-47b3-bcdd-cdad00f8e4d8', path: 'documents'},
      {appId: '36ad5h00-82f0-4e5f-a10b-edfa03be6eb7', path: 'projects'},
    ]);
  }

  public getAppDetails(appId: string): Observable<CustomerApp> {
    return this.appData$.pipe(
      map((apps) => apps.find((app) => app.id === appId)),
      filter((app) => !!app),
    ) as Observable<CustomerApp>;
  }

  private loadAppPermissions(): Observable<CustomerApp[]> {
    // TODO: Get from appPermissionService

    // for now, we use hardcoded app list, but this is how we would do it
    // const url = `${environment.config.appPermissionApiUrl}/users/${userInfo.id}/apppermissions`;
    // return this.http.get(url, { headers: AuthHelper.buildAuthHeaders(this.oauthService.getAccessToken()) })

    return of(staticAppPermissions).pipe(
      flatMap((appPermissions) => {
        if (!appPermissions._embedded || !appPermissions._embedded.appPermissions) {
          return of([]);
        }

        const appsData: BehaviorSubject<CustomerApp[]> = new BehaviorSubject<CustomerApp[]>([]);

        appPermissions._embedded.appPermissions
          .map((item) => {
            const app = new JsonConvert().deserializeObject(item._embedded.app, CustomerApp);
            return this.loadConfigForApp(app);
          })
          .forEach((appObservable) => {
            appObservable.subscribe((appData) => {
              if (appData) {
                appsData.next([...appsData.getValue(), appData].sort((
                  a,
                  b,
                ) => a.sortOrder - b.sortOrder));
              }
            });
          });

        return appsData.asObservable();
      }),
    );
  }

  private loadConfigForApp(app: CustomerApp): Observable<CustomerApp | null> {
    return this.http.get<CustomerAppConfig>(`${app.appUrl}/shell-config.json`, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Allow-Unauthenticated': 'true',  // Do not trigger login flow when using direct login.
      }
    }).pipe(
      map((shellConfig) => {
        const configuredApp = app;
        configuredApp.config = shellConfig;

        this.matIconRegistry.addSvgIcon(app.id, this.domSanitizer.bypassSecurityTrustResourceUrl(app.iconUrl));
        return configuredApp;
      }),
      catchError(() => {
        // eslint-disable-next-line no-console
        console.error('Could not load shell config for', app);
        return of(null);
      }),
    );
  }
}

