import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { mergeMap, Observable, of } from 'rxjs';
import { InvitationService } from '../invite/invitation.service';
import { catchError, map } from 'rxjs/operators';
import { isTryingDirectLogin, UserService } from '@drklein-pk/customer-core-lib';

/**
 * A guard that ensures that the user has entered the invite code.
 */
@Injectable({
  providedIn: 'root'
})
export class InviteAcceptedGuard  {

  constructor(
    private invitationService: InvitationService,
    private userService: UserService,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> {
    if (isTryingDirectLogin()) {
      return of(true);
    }

    return this.userService.getJwtUserInfo(true).pipe(
      mergeMap((userInfo) => {
        // A non-user using direct login is never invited and can access without invite.
        if (!userInfo.authorities.includes('CPORT_USER')) {
          return of(true);
        }

        return this.invitationService.inviteAccepted().pipe(
          map((acceptedState) => {
            if (acceptedState.state === 'COMPLETED') {
              return true;
            }
            const url = acceptedState.confirmationUrl ?? '';
            // We don't construct and return a UrlTree to avoid parsing the invite URL.
            document.location.hash = url.substring(url.indexOf('#') + 1);

            return false;
          }),
          catchError((error) => {
            console.error(error);
            // just assume valid invite
            return of(true);
          }),
        );
      }),
    );
  }

}
