import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { MatomoTracker } from 'ngx-matomo-client';

@Injectable()
export class MatomoService {
  constructor(
    private matomoTracker: MatomoTracker,
  ) {
    this.pollCurrentLocationAndTrack();
  }

  private pollCurrentLocationAndTrack() {
    // Since we could not figure out a way to reliably listen to URL changes,
    // we are falling back to just polling the current location. For analytics
    // this should be good enough. This is also how it's done in TNG
    interval(1000).pipe(
      map(() => window.location.href),
      map((url: string) => {
        const anonymizedUrl = this.anonymizeUrl(url);
        // cut off hash (if present)
        const hashIndex = anonymizedUrl.search('#');
        return hashIndex >= 0 ? anonymizedUrl.substr(hashIndex + 1) : anonymizedUrl;
      }),
      // dedupe
      distinctUntilChanged(),
    ).subscribe((url) => {
      this.matomoTracker.setCustomUrl(url);
      this.matomoTracker.trackPageView(document.title);
    });
  }

  public anonymizeUrl(url: string): string {
    const uuidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g;
    return url.replace(uuidRegex,'***anonymized***');
  };
}
