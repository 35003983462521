import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerAuthService, isTryingDirectLogin, UserService } from '@drklein-pk/customer-core-lib';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard  {
  constructor(
    private userService: UserService,
    private authService: CustomerAuthService,
  ) {
  }

  canActivate(): Observable<boolean> {
    // When trying a direct login, we have no user id.
    return isTryingDirectLogin() ? of(true) : this.userService.getUserId(true).pipe(
      map(() => true),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 || error.status === 403) {
          this.redirectToLogin();
        }
        return of(false);
      }),
    );
  }

  private redirectToLogin() {
    this.authService.redirectUri = window.location.href;
    this.authService.initImplicitFlow();
  }
}
