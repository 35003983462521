<div class="navigation-wrapper" #navigationWrapper>
  <h4 *ngIf="customerName$ | async as customerName" class="mat-body-2 user-salutation">
    {{ 'navigation.greeting' | translate: { name: customerName } }}
  </h4>

  <div id="customer-shell-navigation-items">
    <ng-container *ngFor="let app of apps$ | async">
      <span
        *ngIf="app.config"
        class="mat-body-2 navigation-item"
        [ngClass]="{ selected: selectedAppRoute?.includes(app.config.route) }"
        (click)="activateRoute(app.config.route)"
      >
        {{ app.displayName | translate }}
      </span>
    </ng-container>
  </div>

  <div class="menu-separator"></div>

  <div class="custom-items-section">
    <div class="mat-body-2 navigation-item" (click)="onShowHelp()">{{ 'navigation.help' | translate }}</div>
    <div *ngIf="customer$ | async" class="mat-body-2 navigation-item" (click)="onChangePassword()">
      {{ 'navigation.changePassword' | translate }}
    </div>
    <div class="mat-body-2 navigation-item" (click)="onLogout()">
      <mat-icon class="icon" [fontSet]="'material-icons-outlined'">arrow_circle_right</mat-icon>
      {{ 'navigation.logout' | translate }}
    </div>
  </div>
  <div class="menu-separator"></div>
  <div class="language-switcher mat-body-2">
    <mat-icon class="icon">language</mat-icon>
    <span class="locale-option">
      <input
        type="radio"
        name="locale"
        value="german"
        id="german"
        [attr.checked]="('_locale' | translate) === 'de' ? 'checked' : null"
        (change)="setLocale('de')"
      />
      <label for="german">
        {{ 'navigation.language.german' | translate }}
      </label>
      <img alt="" class="flag" src="assets/flags/DE.svg" height="16" width="16" />
    </span>
    <span class="locale-option">
      <input
        type="radio"
        name="locale"
        value="english"
        id="english"
        [attr.checked]="('_locale' | translate) === 'en' ? 'checked' : null"
        (change)="setLocale('en')"
      />
      <label for="english">
        {{ 'navigation.language.english' | translate }}
      </label>
      <img alt="" class="flag" src="assets/flags/GB.svg" height="16" width="16" />
    </span>
  </div>
</div>
