import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { InvitationService } from '../invite/invitation.service';
import { catchError, map } from 'rxjs/operators';

/**
 * A guard that ensures that the user has _not_ entered the invite code yet.
 */
@Injectable({
  providedIn: 'root'
})
export class InviteNotAcceptedGuard  {

  constructor(
    private router: Router,
    private invitationService: InvitationService,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.invitationService.inviteAccepted().pipe(
      map((acceptedState) => {
        if (acceptedState.state === 'NOT_COMPLETED') {
          return true;
        }

        return this.router.createUrlTree(['welcome']);
      }),
      catchError((error) => {
        console.error(error);
        // just assume invalid invite and pass error handling to invite page
        return of(true);
      }),
    );
  }

}
