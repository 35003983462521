import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { catchError, map, takeWhile, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import cssVars from 'css-vars-ponyfill';
import {isTryingDirectLogin, LANGUAGE_STORAGE_KEY, UserInfo, UserService} from '@drklein-pk/customer-core-lib';
import { LoadingSpinnerService } from './loading-spinner/loading-spinner.service';
import { MatomoService } from './core/matomo.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

/**
 * We need to use a wrapper object for the observable because the userInfo can be null if the user is not logged in.
 * If the value of the observable is null the *ngIf will evaluate to false but the case of userInfo being null is desired.
 */
interface UserInfoWrapper {
  userInfo: UserInfo | null;
}

@Component({
  selector: 'customer-shell',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  public wrappedUserInfo$: Observable<UserInfoWrapper> = isTryingDirectLogin()
    ? of({ userInfo: null })
    : this.userService.getUserInfo(true).pipe(
      tap((userInfo) => {
        if (userInfo?.locale) {
          this.translateService.use(userInfo.locale);
          localStorage.setItem(LANGUAGE_STORAGE_KEY, userInfo.locale);
        } else {
          this.translateService.use(this.translateService.defaultLang);
          localStorage.removeItem(LANGUAGE_STORAGE_KEY);
        }
      }),
      map((userInfo) => ({
        userInfo,
      })),
      catchError(() =>
        of({
          userInfo: null,
        })
      ),
    );

  private isAlive = true;

  constructor(
    // Inject even if not used to initialize the URL watcher.
    private matomoService: MatomoService,
    private router: Router,
    private loadingService: LoadingSpinnerService,
    private userService: UserService,
    private htmlTitleService: Title,
    private translateService: TranslateService,
  ) {
    this.router.events.pipe(takeWhile(() => this.isAlive)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.showSpinner(true);
      }

      if (event instanceof NavigationEnd) {
        this.loadingService.showSpinner(false);
      }

      if (event instanceof NavigationCancel) {
        this.loadingService.showSpinner(false);
      }

      if (event instanceof NavigationError) {
        this.loadingService.showSpinner(false);
      }
    });
  }

  ngOnInit() {
    this.translateService.get('app.title').subscribe(
      (title) => this.htmlTitleService.setTitle(title),
      () => {},
    );
  }

  public ngAfterViewInit(): void {
    /**
     * This function is needed to be able to support css variables in legacy browsers e.g. IE11
     * Usage of 'watch' MUST be true to guarantee that css styles are parsed again if customer apps
     * are loaded.
     */
    cssVars({
      watch: true,
    });
  }

  public ngOnDestroy(): void {
    this.isAlive = false;
  }
}
