import { NgModule } from '@angular/core';
import {mapToCanActivate, PreloadAllModules, Route, Router, RouterModule, Routes} from '@angular/router';
import { AppDetailsResolver } from './core/app-details-resolver.service';
import { AuthGuard } from './core/auth-guard';
import { CustomerAppService } from './core/customer-app.service';
import { InitialNavigationComponent } from './core/initial-navigation.component';
import { UserInfoResolver } from './core/user-info.resolver';
import { InviteComponent } from './invite/invite/invite.component';
import { CustomerAppComponent } from './shell/app-container/customer-app/customer-app.component';
import { InviteAcceptedGuard } from './core/invite-accepted.guard';
import { InviteNotAcceptedGuard } from './core/invite-not-accepted.guard';
import { NonUserSessionTimoutComponent } from './session-timout/non-user-session-timout.component';

const localRoutes: Routes = [
  {
    path: `welcome`,
    pathMatch: 'full',
    redirectTo: 'projects',
  },
  {
    path: `invite/:inviteId`,
    component: InviteComponent,
    pathMatch: 'full',
    resolve: {
      userInfo: UserInfoResolver,
    },
    canActivate: mapToCanActivate([InviteNotAcceptedGuard]),
  },
  {
    path: 'session-ended',
    component: NonUserSessionTimoutComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: mapToCanActivate([AuthGuard]),
    component: InitialNavigationComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(localRoutes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    router: Router,
    appService: CustomerAppService,
  ) {
    appService.getRoutes().subscribe((appRoutes) => {
      const routes: Route[] = appRoutes.map((route) => ({
        path: route.path,
        children: [
          {
            path: '**',
            component: CustomerAppComponent,
            data: {
              appId: route.appId,
            },
            canActivate: mapToCanActivate([
              AuthGuard,
              InviteAcceptedGuard,
            ]),
            resolve: {
              activeApp: AppDetailsResolver,
            },
          },
        ],
      }));

      router.config.unshift(...routes);
    });
  }
}
