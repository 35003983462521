import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import { MatInputModule} from '@angular/material/input';
import { AgentComponent } from './invite/agent/agent.component';
import {InviteComponent} from './invite/invite.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    AgentComponent,
    InviteComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ]
})
export class InviteModule { }
