import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-non-user-session-timout',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './non-user-session-timout.component.html',
  styleUrl: './non-user-session-timout.component.scss'
})
export class NonUserSessionTimoutComponent {

}
