<div class="wrapper mat-typography">
  <h1>{{ 'invite.title' | translate: { name: (customerName$ | async) } }}</h1>

  <div class="invite panel" *ngIf="(loggedIn$ | async) && (invitation$ | async) as invitation; else noInvite">
    <ng-container [ngSwitch]="invitation.status">
      <div *ngSwitchCase="'WAITING_FOR_CONFIRMATION'" class="action-area">
        <p>{{ 'invite.explanation' | translate }}</p>

        <mat-error *ngIf="errorInState">{{ 'invite.error' | translate }}</mat-error>

        <button mat-flat-button color="primary" (click)="sendTwoFactorCode()" [disabled]="errorInState">
          {{ 'invite.sendSms' | translate }}
        </button>
        <p class="mat-caption">
          {{ 'invite.isBeingSent' | translate: { phoneNumber: invitation.redactedMobilePhone } }}
        </p>
      </div>

      <div *ngSwitchCase="'WAITING_FOR_TWO_FACTOR_CODE'" class="action-area">
        <ng-container *ngIf="!invitation.redactedMobilePhone">
          <p>
            {{ 'invite.enterCode' | translate }}
          </p>

          <div class="text-code-input-wrapper">
            <input
              [ngClass]="errorInState ? 'error text-input' : 'text-input'"
              matInput
              name="textCode"
              drkTrimOnBlur
              (keydown.enter)="trySubmit(false)"
              [formControl]="textCodeFormControl"
            />

            <mat-error class="code-error" *ngIf="errorInState">{{ 'invite.invalidInput' | translate }}</mat-error>
          </div>
        </ng-container>

        <ng-container *ngIf="invitation.redactedMobilePhone">
          <p>
            {{ 'invite.sentToPhone' | translate: { phoneNumber: invitation.redactedMobilePhone } }}
          </p>

          <form [formGroup]="twoFactorCodeFormGroup">
            <input
              *ngFor="let control of twoFactorCodeFormGroup.controls | keyvalue"
              [ngClass]="errorInState ? 'error code-input' : 'code-input'"
              matInput
              [name]="control.key"
              #codeInput
              maxlength="1"
              type="text"
              pattern="\d*"
              drkTrimOnBlur
              (keydown.enter)="trySubmit(true)"
              [formControlName]="control.key"
            />
            <mat-error class="code-error" *ngIf="errorInState">{{ 'invite.invalidCode' | translate }}</mat-error>
          </form>
        </ng-container>
      </div>

      <div *ngSwitchCase="'FINISHED'" class="action-area">
        <p>{{ 'invite.Thanks' | translate }}</p>

        <a [routerLink]="'/projects'" mat-flat-button color="primary">{{ 'invite.CustomerPortalLink' | translate }}</a>
      </div>

      <!-- error cases -->
      <div *ngSwitchCase="'INVALIDATED'" class="action-area">
        <p>{{ 'invite.state.invalidInvite' | translate }}</p>
      </div>
      <div *ngSwitchCase="'EXPIRED'" class="action-area">
        <p>{{ 'invite.state.expiredInvite' | translate }}</p>
      </div>
      <div *ngSwitchCase="'FAILED'" class="action-area">
        <p>{{ 'invite.state.failedInvite' | translate }}</p>
      </div>
      <div *ngSwitchDefault>
        <p>{{ 'invite.state.unknownInviteStatus' | translate }}</p>
      </div>
    </ng-container>

    <app-agent [agent]="invitation.invitedBy"></app-agent>

    <div class="button-wrapper">
      <button
        *ngIf="invitation.status === 'WAITING_FOR_TWO_FACTOR_CODE' && !invitation.redactedMobilePhone"
        mat-flat-button
        color="primary"
        class="submit-button"
        [disabled]="!!textCodeFormControl.errors"
        (click)="trySubmit(false)"
      >
        {{ 'invite.activate' | translate }}
      </button>
    </div>
  </div>

  <ng-template #noInvite>
    <div class="invite panel" *ngIf="errorInState">
      <div class="action-area">
        <mat-error>{{ 'invite.noInvite' | translate }}</mat-error>
      </div>
    </div>
  </ng-template>
</div>
