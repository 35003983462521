import { animate, AnimationBuilder, AnimationFactory, AnimationMetadata, sequence, style } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AgentService,
  BasicCustomer,
  CustomerService,
  Gender,
  getAuthUrl, LANGUAGE_STORAGE_KEY, LOCALE_UPDATED_MESSAGE_KEY, LocaleUpdatedMessage, LOGOUT_MESSAGE_KEY,
  UserInfo,
  UserService,
} from '@drklein-pk/customer-core-lib';
import { fromEvent, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { CustomerApp } from '../../core/customer-app';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { CustomerAppService } from '../../core/customer-app.service';
import { NavigationService } from '../../core/navigation.service';
import { CustomerNameService } from '../customer-name.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'customer-shell-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  public customerName$: Observable<string>;

  @ViewChild('navigationWrapper', { static: true, read: ElementRef })
  public wrapperElementRef!: ElementRef;

  public apps$: Observable<CustomerApp[]>;

  public customer$!: Observable<BasicCustomer | null>;

  public selectedAppRoute!: string;

  private readonly MENU_OPEN_CSS_ANIMATION_DURATION = 150;

  private readonly OVERFLOW_ANIMATION_DURATION = 50;

  private readonly MENU_OPEN_BOX_SHADOW = '0px 4px 10px 0px rgba(114,121,122,0.3)';

  private readonly AUTH_TOKEN_CLEANUP_DELAY = 250;

  private readonly SMALL_SCREEN_BREAKPOINT = '(max-width: 480px)'; // -> see $max-mobile-menu-width

  private _destroyed = false;

  private _clickOutsideSubscription?: Subscription;

  constructor(
    private appService: CustomerAppService,
    private customerService: CustomerService,
    private customerNameService: CustomerNameService,
    private navigationService: NavigationService,
    private router: Router,
    private elementRef: ElementRef,
    private animationBuilder: AnimationBuilder,
    private breakpointObserver: BreakpointObserver,
    private agentService: AgentService,
    private userService: UserService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.apps$ = this.appService.getApps().pipe(this.genderConsultantsAppDisplayName());
    this.setupRouterEventsSubscription();
    this.customerName$ = customerNameService.customerName$;
  }

  @Input()
  set userInfo(newValue: UserInfo) {
    this.customerNameService.setCurrentUser(newValue);
  }

  public ngOnInit() {
    this.selectedAppRoute = this.appRouteFromCurrentUrl(window.location.hash);

    if (this.userInfo) {
      this.customer$ = this.customerService.getCustomerByUid(this.userInfo.id).pipe(catchError(() => of(null)));
    } else {
      this.customer$ = of(null);
    }
  }

  public ngAfterViewInit() {
    this.setupNavigationServiceSubscription();
  }

  public ngOnDestroy() {
    this._destroyed = true;
    this.removeClickOutsideSubscription();
  }

  public onChangePassword() {
    window.location.href = `${getAuthUrl(window.location)}/password/change?return_uri=${window.location.href}`;
  }

  public onShowHelp() {
    window.open('https://www.drklein.de/kundenbetreuung-baufinanzierung.html', '_blank');
    this.onCloseNavigation();
  }

  public onLogout() {
    // we want to log out all apps, so we post a message to the core-lib that is processed by all instances and triggers a logout
    window.postMessage(LOGOUT_MESSAGE_KEY, '*');

    // since we don't know how long the core lib takes to log out the apps but it causes a bug if it takes longer than
    // OAUTH_TOKEN_CLEANUP_DELAY, so we clear the whole storage here to avoid nonce-issues
    sessionStorage.clear();

    // usually the log-out from the core lib should not take longer than this timeout, so we try to wait until the lib is done with all apps
    timer(this.AUTH_TOKEN_CLEANUP_DELAY).subscribe(() => {
      this.router.navigate(['welcome']);
      this.onCloseNavigation();
    });
  }

  public onCloseNavigation() {
    this.navigationService.closeNavigation();
  }

  public setLocale(locale: string): void {
    this.translateService.get('_locale').subscribe((currentLocale) => {
      if (currentLocale !== locale) {
        // write remote locale of user
        this.userService.getJwtUserInfo().subscribe({
          next: (userInfo) => {
            if (userInfo.authorities.includes('CPORT_USER')) {
              this.userService.setPreferredLocale(locale, { remote: true }).subscribe({
                error: (error) => console.error(error),  // fail relatively silently
              });
            }
          },
          error: (error) => console.error(error),
        });
        // update locale currently used in the shell
        this.translateService.use(locale);
        // update locale stored in local storage
        localStorage.setItem(LANGUAGE_STORAGE_KEY, locale);
        // notify the current app about the new locale
        const localeMessage: LocaleUpdatedMessage = {
          type: LOCALE_UPDATED_MESSAGE_KEY,
          locale,
        };
        window.postMessage(localeMessage, '*');
      }
    });
  }

  public activateRoute(route: string) {
    this.onCloseNavigation();

    timer(this.MENU_OPEN_CSS_ANIMATION_DURATION).subscribe(() => {
      window.location.hash = route;
    });
  }

  private genderConsultantsAppDisplayName() {
    return map((apps: CustomerApp[]) => {
      const CONSULTANT_APP_ID = '36693700-82f0-4e5f-a10b-edfa03be6eb7';
      const consultantsApp = apps.find((app) => app.id === CONSULTANT_APP_ID);
      if (consultantsApp) {
        this.agentService.getAgentGender().subscribe((gender) => {
          consultantsApp.displayName = gender === Gender.FEMALE ? _('apps.consultant.name.female') : _('apps.consultant.name.male');
        });
      }
      return apps;
    });
  }

  private setupRouterEventsSubscription() {
    this.router.events.pipe(takeWhile(() => !this._destroyed)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedAppRoute = this.appRouteFromCurrentUrl(event.urlAfterRedirects);
      }
    });
  }

  private setupNavigationServiceSubscription() {
    this.navigationService.navigationOpen$.pipe(takeWhile(() => !this._destroyed)).subscribe((isOpen) => {
      if (isOpen) {
        this.createAndPlayOpenAnimation();
        this.addClickOutsideSubscription();
      } else {
        this.createAndPlayCloseAnimation();
        this.removeClickOutsideSubscription();
      }
    });
  }

  private appRouteFromCurrentUrl(url: string): string {
    return url.split('?')[0];
  }

  private addClickOutsideSubscription() {
    this.removeClickOutsideSubscription();

    this._clickOutsideSubscription = fromEvent(document, 'click').subscribe((event) => {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        this.onCloseNavigation();
      }
    });
  }

  private removeClickOutsideSubscription() {
    if (this._clickOutsideSubscription) {
      this._clickOutsideSubscription.unsubscribe();
    }
  }

  private createAndPlayOpenAnimation() {
    let openAnimationStyle = this.openAnimationStyleDesktop();
    if (this.breakpointObserver.isMatched(this.SMALL_SCREEN_BREAKPOINT)) {
      openAnimationStyle = this.openAnimationStyleMobile();
    }

    const openAnimationFactory = this.buildAnimationFactory([
      sequence([
        animate(this.MENU_OPEN_CSS_ANIMATION_DURATION, style(openAnimationStyle)),
        animate(
          this.OVERFLOW_ANIMATION_DURATION,
          style({
            overflowY: 'auto',
          })
        ),
      ]),
    ]);
    this.playAnimationFromFactory(openAnimationFactory);
  }

  private openAnimationStyleDesktop(): any {
    return {
      height: `${this.wrapperElementRef.nativeElement.clientHeight}px`,
      boxShadow: this.MENU_OPEN_BOX_SHADOW,
    };
  }

  private openAnimationStyleMobile(): any {
    const headerHeight = getComputedStyle(this.document.documentElement).getPropertyValue('--shell-header-height');
    const safeAreaInsetBottom = getComputedStyle(this.document.documentElement).getPropertyValue(
      '--safe-area-inset-bottom'
    );
    return {
      height: `calc(100vh - (${headerHeight} + ${safeAreaInsetBottom}))`,
      boxShadow: 'none',
    };
  }

  private createAndPlayCloseAnimation() {
    const closeAnimationFactory = this.buildAnimationFactory([
      sequence([
        animate(
          this.OVERFLOW_ANIMATION_DURATION,
          style({
            overflowY: 'hidden',
          })
        ),
        animate(
          this.MENU_OPEN_CSS_ANIMATION_DURATION,
          style({
            height: '0',
            boxShadow: 'none',
          })
        ),
      ]),
    ]);

    this.playAnimationFromFactory(closeAnimationFactory);
  }

  private buildAnimationFactory(animations: AnimationMetadata[]): AnimationFactory {
    return this.animationBuilder.build(animations);
  }

  private playAnimationFromFactory(factory: AnimationFactory) {
    // use the returned factory object to create a player
    const player = factory.create(this.elementRef.nativeElement);
    player.play();
  }
}
