import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, takeWhile } from 'rxjs/operators';
import { CustomerApp } from '../../../core/customer-app';
import { LoadingSpinnerService } from '../../../loading-spinner/loading-spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerAuthService } from '@drklein-pk/customer-core-lib';

/**
 * Load and show a single micro frontend application as per our protocol.
 *
 * The component will dynamically create script tags and a custom element that will host the
 * selected application. Applications that were loaded once will "stay active" in the DOM if the user
 * switches to another app by using an "Empty Route".
 *
 * @see https://goo.gl/5bqH1F
 */
@Component({
  template: `<div class="app-container" [ngClass]="{ 'app-not-loaded': (appIsLoaded$ | async) === false }" #appContainer></div>
`,
  styleUrls: ['customer-app.component.scss'],
  selector: 'customer-app',
})
export class CustomerAppComponent implements OnDestroy, OnInit {
  public appIsLoaded$: Observable<boolean>;

  @ViewChild('appContainer', { static: true })
  private appContainer!: ElementRef;

  private domChangeObserver?: MutationObserver;

  private activeApp?: CustomerApp;

  private appIsLoadedSubject: BehaviorSubject<boolean>;

  private _active = true;

  constructor(
    private loadingService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private titleService: Title,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private customerAuthService: CustomerAuthService,
  ) {
    this.appIsLoadedSubject = new BehaviorSubject(false);
    this.appIsLoaded$ = this.appIsLoadedSubject.asObservable();
  }

  public ngOnInit() {
    this.route.data.pipe(takeWhile(() => this._active)).subscribe((data) => {
      this.updateActiveApp(data.activeApp);
    });
  }

  public ngOnDestroy() {
    if (this.domChangeObserver) {
      this.domChangeObserver.disconnect();
    }
    this._active = false;
  }

  private updateActiveApp(app: CustomerApp) {
    this.activeApp = app;
    this.translateService.get(app.displayName).subscribe((title) => this.titleService.setTitle(title));

    this.appIsLoadedSubject.next(false);
    this.loadStyles(app);
    this.loadScripts(app);
    const appContainerElem = this.appContainer.nativeElement as HTMLElement;
    const appElement = this.renderer.createElement(app.config!.elementName);

    this.loadingService.showSpinner(true);
    this.domChangeObserver = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutationRecord: MutationRecord) => {
        if (mutationRecord.target.nodeName.toLowerCase() === this.activeApp!.name.toLowerCase()) {
          /**
           * If 'ng-version' attribute was added to the element the angular is loaded inside the html element
           */
          if (mutationRecord.attributeName === 'ng-version') {
            this.appIsLoadedSubject.next(true);
            this.domChangeObserver?.disconnect();
            this.loadingService.showSpinner(false);
          }
        }
      });
    });

    this.domChangeObserver.observe(appElement, { attributes: true });

    appElement.innerHTML = '';
    this.renderer.appendChild(appContainerElem, appElement);
    this.renderer.addClass(appElement, 'app-full-height');
    if (this.customerAuthService.hasValidAccessToken()) {
      this.renderer.setAttribute(appElement, 'authentication-token', this.customerAuthService.getAccessToken());
    } else {
      this.customerAuthService.events.pipe(
        filter((evt) => evt.type === 'token_received'),
        take(1),
      ).subscribe(() => {
        this.renderer.setAttribute(appElement, 'authentication-token', this.customerAuthService.getAccessToken());
      });
    }
  }

  private loadStyles(app: CustomerApp) {
    app.config!.styles?.forEach((styleUrlOrPath) => {
      const styleElement = document.createElement('link');
      styleElement.rel = 'stylesheet';

      if (styleUrlOrPath.indexOf('http') === 0 || styleUrlOrPath.indexOf('//') === 0) {
        styleElement.href = styleUrlOrPath;
      } else {
        styleElement.href = `${app.appUrl}/${styleUrlOrPath}`;
      }

      document.head.appendChild(styleElement);
    });
  }

  private loadScripts(app: CustomerApp) {
    if (customElements.get(app.config!.elementName)) {
      return;
    }
    app.config!.scripts
      .filter(
        (scriptName) =>
          /**
           * Polyfills.js file was aready loaded by into DOM by the shell -> Skip it here.
           * If we attempt to load it again we get "Zone already loaded" and "Zone Promise override error" at least in IE11
           */
          !scriptName.toLowerCase().includes('polyfills')
      )
      .forEach((scriptFileName) => {
        this.loadScript(app.appUrl, scriptFileName);
      });
  }

  private loadScript(baseUrl: string, scriptFileName: string) {
    const scriptElement = document.createElement('script');
    const scriptUrl = `${baseUrl}/${scriptFileName}`;
    if (this.scriptUrlPointsToModernES2015(scriptUrl)) {
      scriptElement.type = 'module';
    } else if (this.scriptUrlPointsToLegacyES5(scriptUrl)) {
      scriptElement.setAttribute('nomodule', '');
      scriptElement.setAttribute('defer', '');
    } else {
      scriptElement.type = 'text/javascript';
    }
    scriptElement.src = scriptUrl;
    document.head.appendChild(scriptElement);
  }

  private scriptUrlPointsToModernES2015(scriptUrl: string): boolean {
    return scriptUrl.toLowerCase().indexOf('-es2015.') !== -1;
  }

  private scriptUrlPointsToLegacyES5(scriptUrl: string): boolean {
    return scriptUrl.toLowerCase().indexOf('-es5.') !== -1;
  }
}
